<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper"></div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" />
                <el-table-column label="设备号" prop="equipment_sn" />
                <el-table-column label="生产序列号" prop="production_sn" />
                <el-table-column label="资产序列号" prop="assets_sn" />
                <el-table-column label="累计数据条数" prop="data_number" />
                <el-table-column label="累计事件条数" prop="event_number" />
                <el-table-column label="加速度状态" prop="speed_status" />
                <el-table-column label="角度状态" prop="angle_status" />
                <el-table-column label="最新数据时间" prop="data_at" />
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import { GET_EQUIPMENT_ONLINE_LIST } from "@/api/equipment";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([]);

let searchOptions = ref({});

let searchForm = ref({
    name: null
});

// 获取数据
let getDataList = () => {
    GET_EQUIPMENT_ONLINE_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};
</script>
