import { httpDelete, httpGet, httpPut } from "@/api/request";
import $store from "@/store";

// 获取在线电杆列表
export function GET_EQUIPMENT_ONLINE_LIST(params) {
    return httpGet("/online", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取智能终端设备列表
export function GET_EQUIPMENT_TERMINAL_LIST(params) {
    return httpGet("/terminal", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 角度锁定
export function ANGLE_LOCK_SINGLE(params) {
    return httpPut(`/terminal/angle/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 批量角度锁定
export function ANGLE_LOCK_BATCH(params) {
    return httpPut("/terminal/angle/batch", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 更新偏差
export function UPDATE_OFFSET(params) {
    return httpPut(`/terminal/triaxial/${params.type}/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 批量更新偏差
export function BATCH_UPDATE_OFFSET(params) {
    return httpPut(`/terminal/triaxial/${params.type}/batch`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 批量删除智能设备
export function BATCH_DELETE_EQUIPMENT_TERMINAL(params) {
    return httpDelete("/terminal", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
